import React from "react"
import { Link } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Header = () => (
  <div>
    <div className="header">
      <ul>
        <li>
          <AniLink cover to="/" activeClassName="active" bg="#060606" duration={1} direction="top">
            Home
          </AniLink>
          {/* <Link to="/" activeClassName="active">Home</Link> */}
        </li>
        <li>
          {/* <Link to="/" activeClassName="active">About</Link> */}
        </li>
        <li>
        <AniLink cover to="/notes" activeClassName="active" bg="#060606" duration={1} direction="top">
            Notes
          </AniLink>
          {/* <Link to="/notes" activeClassName="active">Notes</Link> */}
        </li>
      </ul>
    </div>
  </div>
)

export default Header
